export enum LeadSourceType {
  Alfalfa = 'ALFALFA',
  Amazon = 'AMAZON',
  AmazonBenefitsDemo = 'AMAZONBENEFITSDEMO',
  Backfill = 'BACKFILL',
  BuilderReferral = 'BUILDERREFERRAL',
  Bankrate = 'BANKRATE',
  BankrateAlfie = 'BANKRATEALFIE',
  BankrateCall = 'BANKRATECALL',
  BankrateCashOutEmail = 'BANKRATECASHOUTEMAIL',
  BankrateCPC = 'BANKRATECPC',
  BankrateDigiFlex = 'BANKRATEDIGIFLEX',
  BankrateEmail = 'BANKRATEEMAIL',
  BankrateFedEmail = 'BANKRATEFEDEMAIL',
  BankratePrimary = 'BANKRATEPRIMARY',
  BankratePro = 'BANKRATEPRO',
  BankrateSecondary = 'BANKRATESECONDARY',
  Bing = 'BING',
  CalendlyAppointment = 'CALENDLYAPPOINTMENT',
  ConsumerAffairs = 'CONSUMERAFFAIRS',
  CPC = 'CPC',
  CustomerReferral = 'CUSTOMERREFERRAL',
  EmployeeLoans = 'EMPLOYEELOANS',
  ExistingCustomerReferral = 'EXISTINGCUSTOMERREFERRAL',
  Facebook = 'FACEBOOK',
  Facet = 'FACET',
  Fed = 'FED',
  FreeRateUpdate = 'FREERATEUPDATE',
  HarrisTeeter = 'HARRISTEETER',
  HomeEquityCPC = 'HOMEEQUITYCPC',
  HomeEquityPrimary = 'HOMEEQUITYPRIMARY',
  HomeEquitySecondary = 'HOMEEQUITYSECONDARY',
  HomeLight = 'HOMELIGHT',
  HSH = 'HSH',
  InboundCall = 'INBOUNDCALL',
  LendingTree = 'LENDINGTREE',
  LoanEstimateComparison = 'LOANESTIMATECOMPARISON',
  LowerMyBills = 'LOWERMYBILLS',
  LowerMyBillsShortForm = 'LOWERMYBILLSSHORTFORM',
  MyAgentFinder = 'MYAGENTFINDER',
  MilitaryVALoan = 'MILITARYVALOAN',
  PersonalReferral = 'PERSONALREFERRAL',
  PropertyMate = 'PROPERTYMATE',
  Puma = 'PUMA:UI',
  PurchaseReferrals = 'PURCHASEREFERRALS',
  RateZip = 'RATEZIP',
  RealtorReferral = 'REALTORREFERRAL',
  Refily = 'REFILY',
  RetClientForLifeOB = 'RETCLIENTFORLIFEOB',
  Reporting = 'REPORTING',
  RVMN = 'RVMN',
  SageMortgageEmail = 'SAGEMORTGAGEEMAIL',
  SageRadio = 'SAGERADIO',
  SageWebsite = 'SAGEWEBSITE',
  SEM = 'SEM',
  SMARTASSET = 'SMARTASSET',
  SupportPhoneNumber = 'SUPPORTPHONENUMBER',
  TheMortgageReport = 'THEMORTGAGEREPORT',
  Top10 = 'TOP10',
  Transunion = 'TRANSUNION',
  Unmapped = 'UNMAPPED',
  Zillow = 'ZILLOW',
}
